<template>
  <div class="pb-16">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card card-custom mt-16">
          <div class="card-body text-center">
            <h2 class="pb-4">{{$t('profile_settings.thanks')}}</h2>
            <p>{{$t('profile_settings.thanks_message')}}</p>
            <p>{{$t('profile_settings.thanks_message_2')}}</p>

            <v-btn v-b-tooltip.hover :title="tooltip_text" @click="copyText" class="btn btn-primary btn-lg text-white" style="text-transform: unset;">
              <i class="fa fa-copy"></i> {{link}}
            </v-btn>

<!--            <input type="text" v-model="text" />-->
<!--            <button @click="copyText(link)">{{link}}</button>-->
          
          </div>
        </div>
        <div class="card card-custom mt-10">
          <div class="card-header align-items-center">
            <h3>{{$t('account_information')}}</h3>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <div class="col-lg-6 mb-5">
                <label>{{$t('company.first_name')}}</label>
                <input type="text" class="form-control" :class=" validation && validation.first_name ? 'is-invalid' : ''" v-model="data.first_name" :placeholder="$t('users.first_name')"/>
                <span v-if="validation && validation.first_name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.first_name[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{$t('company.last_name')}}</label>
                <input type="text" class="form-control" :class=" validation && validation.last_name ? 'is-invalid' : ''" v-model="data.last_name" :placeholder="$t('users.last_name')"/>
                <span v-if="validation && validation.last_name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.last_name[0] }}
                </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{$t('company.currency')}}</label>
                <select name="" id="currency" v-model="data.currency_id" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.currency_id[0] }}
                </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{$t('company.country')}}</label>
                <div class="input-group">
                  <select name="" id="countries" v-model="data.country_id" class="custom-select" :class="validation && validation.country_id ? 'is-invalid' : ''">
                    <option v-for="row in countries" :value="row.code2" :key="row.code2">
                      {{ row.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation.country_id" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.country_id[0] }}
                                        </span>
                </div>
              </div>
<!--              <div class="col-lg-6 mb-5">-->
<!--                <label>{{$t('company.city')}}</label>-->
<!--                <div class="input-group">-->
<!--                  <select name="" id="cities" v-model="data.city_id" class="custom-select" :class="validation && validation.city_id ? 'is-invalid' : ''">-->
<!--                    <option v-for="row in cities" :value="row.id" :key="row.id">-->
<!--                      {{ row.name }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                  <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">-->
<!--                                            {{ validation.city_id[0] }}-->
<!--                                        </span>-->
<!--                </div>-->

<!--              </div>-->
<!--              <div class="col-lg-6 mb-5">-->
<!--                <label>{{$t('company.country')}}</label>-->
<!--                <div class="input-group">-->

<!--                      <multiselect-->
<!--                          :class="validation && validation.country_id ? 'is-invalid' : ''"-->
<!--                          v-model="country"-->
<!--                          :placeholder="$t('branches.country')"-->
<!--                          label="name"-->
<!--                          track-by="code2"-->
<!--                          :options="countries"-->
<!--                          :multiple="false"-->
<!--                          :taggable="false"-->
<!--                          :show-labels="false"-->
<!--                          :show-no-options="false"-->
<!--                          :show-no-results="false"-->
<!--                          @search-change="getCountries($event)"-->
<!--                          :internal-search="false">-->
<!--                      </multiselect>-->

<!--                  <span v-if="validation && validation.country_id" class="fv-plugins-message-container invalid-feedback">-->
<!--                      {{ validation.country_id[0] }}-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-6 mb-5">-->
<!--                <label>{{$t('company.city')}}</label>-->
<!--                <div class="input-group">-->
<!--                  <multiselect-->
<!--                      :class="validation && validation.city ? 'is-invalid' : ''"-->
<!--                      v-model="city"-->
<!--                      :placeholder="$t('company.city')"-->
<!--                      label="name"-->
<!--                      track-by="id"-->
<!--                      :options="cities"-->
<!--                      :multiple="false"-->
<!--                      :taggable="true"-->
<!--                      :show-labels="false"-->
<!--                      :show-no-options="false"-->
<!--                      :show-no-results="false"-->
<!--                  >-->
<!--                  </multiselect>-->
<!--                </div>-->
<!--              </div>-->
              <div class="col-lg-6 mb-5">
                <label>{{$t('company.mobile')}}</label>
                <div class="input-group">
<!--                  <input type="text" class="form-control" :class="validation && validation.mobile ? 'is-invalid' : ''" v-model="data.mobile" :placeholder="$t('company.mobile')"/>-->
                  <vue-tel-input v-model="data.mobile" :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                  <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">{{ validation.mobile[0] }}</span>
                </div>
              </div>

<!--              <div class="col-lg-6 mb-5">-->
<!--                <label>{{$t('company.phone')}}</label>-->
<!--                <div class="input-group">-->
<!--                  <input type="text" class="form-control" :class="validation && validation.telephone ? 'is-invalid' : ''" v-model="data.telephone" :placeholder="$t('company.phone')"/>-->
<!--                  <span v-if="validation && validation.telephone" class="fv-plugins-message-container invalid-feedback">{{ validation.telephone[0] }}</span>-->
<!--                </div>-->
<!--              </div>-->

            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-6">
                <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
              </div>
              <div class="col-lg-6 d-flex justify-content-end">
                <button type="button" class="btn btn-secondary mr-2" @click="goToLogin">{{ $t('skip') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Multiselect from 'vue-multiselect';
import Vue from "vue";

export default {
  name: "ProfileSettings",
  components:{'multiselect': Multiselect},
  data(){
    return{
      mainRoute: 'profile_setting',
      mainRouteDependency: 'dependency',

      link: '',
      data: {
        first_name: null,
        last_name: null,
        currency_id: 4,
        country_id: 'SA',
        mobile: null,
        // telephone: null,
        // city_id: null,
      },

      validation: null,
      country: null,
      city: null,
      countries: [],
      currencies: [],
      cities: [],
      tooltip_text: this.$t('copy'),
    }
  },
  watch:{
    // "data.country_id": function (val) {
    //   if (val) {
    //     this.getCities(val);
    //   }
    // },
    // "country": function (val) {
    //   if (val) {
    //     this.data.country_id = val.code2;
    //     this.getCities(val.code2);
    //   }else {
    //     this.data.country_id = null;
    //   }
    // },
    // "city": function (val) {
    //   if (val) {
    //     this.data.city_id = val.id;
    //   }else {
    //     this.data.city_id = null;
    //   }
    // },
  },
  methods:{
    save() {
      Vue.prototype.$postStatus = true;
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/login');
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    // getCountries(filter) {
    //   if(filter && filter.length >= 3){
    //     ApiService.get(`${this.mainRouteDependency}/countries`,{params:{filter: filter}}).then((response) => {
    //       this.countries = response.data.data;
    //     });
    //   }
    // },
    getCountries() {
        ApiService.get(`${this.mainRouteDependency}/countries`).then((response) => {
          this.countries = response.data.data;
        });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getCities(id) {
      ApiService.get(this.mainRouteDependency + `/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    copyText(){
      navigator.clipboard.writeText(this.link);
      this.tooltip_text = this.$t('copied')+': '+this.link;
      setTimeout(()=>{
        this.tooltip_text = this.$t('copy');
      }, 1000);
    },
    goToLogin(){
      this.$router.push('/login');
    }
  },
  mounted() {
    let domain = (new URL(window.location));
    this.link = domain.hostname;
    this.getCurrencies();
    this.getCountries();
  }
}
</script>

<style scoped>

</style>